<template>
  <div class="content">
    <div class="topBox">
      <div class="topText">
        <div class="w-22 tc-title mb-15">
          Hyper-Converged Intelligent Monitoring Platform
        </div>
        <div class="w-29 tc-normal mb-20">
          <!-- Including video surveillance, AI, Internet of things, big data
          analysis and other subsystems. Serve industrial, transportation and
          other scenarios, and support multiuser, cross industry and cross
          equipment seamless connection. -->
          Including video surveillance, AI, Internet of things, big data analysis, etc.
        </div>
        <div class="trialBtn flex center" @click="goTrial">FREE TRIAL</div>
      </div>
      <div class="contactUs flex space-between alignCenter">
        <img src="@/assets/img/logo/Vector.png" alt="" />
        <img src="@/assets/img/logo/Contact Us +0065 97941186.png" alt="" />
      </div>
    </div>
    <div class="middelBox">
      <el-carousel
        type="card"
        class="carousel"
        :height="bannerHeight+'px'"
      >
        <el-carousel-item v-for="(item, index) in picArr" :key="index">
          <img ref="bannerImg" class="bannerImg" :src="item" alt="" @load="loadImg" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="footerBox flex space-around alignCenter">
      <div class="footerItem flex center">
        <div class="tx-center">
          <img
            class="mb-40"
            src="@/assets/img/logo/LOGO+chasingai.svg"
            alt=""
          />
          <div class="tx-20">+0065 97941186</div>
        </div>
      </div>
      <div class="footerItem flex center">
        <div class="tx-center">
          <img
            class="mb-20"
            src="@/assets/img/logo/BusinessCooperation.png"
            alt=""
          />
          <div class="footerTitle">Business Cooperation</div>
          <div class="tx-20">JoeRao@chasingai.com</div>
        </div>
      </div>
      <div class="footerItem flex center">
        <div class="tx-center">
          <img
            class="mb-20"
            src="@/assets/img/logo/ChannelPartnership.png"
            alt=""
          />
          <div class="footerTitle">Channel Partnership</div>
          <div class="tx-20">AlexLiu@chasingai.com</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router/index.js";
export default {
  data() {
    return {
      picArr: [
        require("@/assets/img/showoff/1.png"),
        require("@/assets/img/showoff/2.png"),
        require("@/assets/img/showoff/3.png"),
        require("@/assets/img/showoff/4.png"),
        require("@/assets/img/showoff/5.png"),
      ],

      bannerHeight: "",
    };
  },
  mounted() {
    window.addEventListener("resize",() => {
        this.imgLoad();
      },false);
  },
  methods: {
    goTrial() {
      router.push("/FreeTrial");
    },
    loadImg() {
      this.$nextTick(() => {
        this.bannerHeight =
          this.$refs.bannerImg && this.$refs.bannerImg.length
            ? this.$refs.bannerImg[0].height
            : ""; //关键的一步
      });
    },
  },
};
</script>

<style>
.content {
  width: 100%;
  overflow: auto;
  background-color: #fff;
}

.footerBox {
  width: 100%;
  height: 54vh;
  background: #030c2d;
}

.footerItem {
  width: 27%;
  height: 58.3%;
  background: rgba(29, 53, 137, 1);
  color: #fff;
}

.footerTitle {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 30px;
}
</style>

<style scoped>
.topBox {
  /* width: 45%;
  padding: 60px 80px; */
  width: 100%;
  height: 100vh;
  background: url("../assets/img/logo/headerback.png") no-repeat;
  background-size: 100% 100%;

  position: relative;
}

.topText {
  width:100%;
  position: absolute;
  top: 26%;
  left: 6.5%;
}

.trialBtn {
  width: 9.3%;
  height: 5.9%;
  background: linear-gradient(180deg, #02ffe2 0%, #0277ff 100%);
  border-radius: 30px;
  font-family: "Inter";
  color: #fff;
  font-size: 20px;
  padding: 16px;
  font-weight: 700;
  cursor: pointer;
}

.contactUs {
  width: 17%;
  position: absolute;
  bottom: 7%;
  left: 5%;
}

.middelBox {
  width: 100%;
  height: 100vh;
  background: url("../assets/img/logo/bgPic2.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  /* padding: 8% 0; */
}
.middelBox .el-carousel {
  width: 100%;
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
}

.bannerImg {
  width: 100%;
  height: inherit;
  min-height: 449px;
}

.el-carousel__item--card.is-active{
  border: 2px solid rgba(255, 255, 255, 0.7);
  border-radius: 15px;
}
/deep/ .el-carousel__indicators--outside{
  margin-top:60px !important;
}

.mb-15{
  margin-bottom:34px;
}

.w-22{
  width:30% !important;
  color:#fff;
}

.w-29{
  color:#dedede;
}

</style>